/**
 * Third-party libraries.
 */
import { useEffect, useRef } from "react";

/** The default events to listen to. */
const DEFAULT_EVENTS = ["mousedown", "touchstart"];

/**
 * A hook that allows you to handle clicks outside of a component by passing a ref.
 * When a mouse click is detected outside of that ref, the handler is called.
 *
 * Useful for cases like complex modals, or dropdowns, where a click outside should close it.
 * Or also for detecting outside clicks from two elements.
 *
 * @example
 *   const ref = useClickOutside(() => console.log("Detected click outside!"));
 *
 * <div ref={ref}>Click Outside Me!</div>
 */
export function useClickOutside<T extends HTMLElement = any>(params: {
  /** The handler to be called when the click is detected outside of the ref. */
  handler: () => void;
  /** Events to listen to. @defaultValue ["mousedown", "touchstart"] */
  events?: string[] | null;
  /** List of refs or multiple refs. */
  nodes?: (HTMLElement | null)[];
}) {
  const { handler, events, nodes } = params;
  const ref = useRef<T>();

  useEffect(() => {
    const listener = (event: any) => {
      const { target } = event ?? {};
      if (Array.isArray(nodes)) {
        const shouldIgnore =
          target?.hasAttribute("data-ignore-outside-clicks") ||
          (!document.body.contains(target) && target.tagName !== "HTML");
        const shouldTrigger = nodes.every(
          (node) => !!node && !event.composedPath().includes(node),
        );
        shouldTrigger && !shouldIgnore && handler();
      } else if (ref.current && !ref.current.contains(target)) {
        handler();
      }
    };

    (events || DEFAULT_EVENTS).forEach((fn) =>
      document.addEventListener(fn, listener),
    );

    return () => {
      (events || DEFAULT_EVENTS).forEach((fn) =>
        document.removeEventListener(fn, listener),
      );
    };
  }, [ref, handler, nodes, events]);

  return ref;
}
