/**
 * Third-party libraries.
 */
import { Button, Collapse, CollapseProps, Tooltip } from "antd";
import { useMemo, useState } from "react";

/**
 * Project components.
 */
import {
  CommunicationDirection,
  CommunicationLogStatus,
  CommunicationLogUser,
  useCommunicationLogContext,
} from "@/components/client/communication-log";
import { CommunicationLogUtility } from "@/components/client/communication-log/utilities";
import { FieldDisplayOnly } from "@/components/client/field";
import { useCallCreateMutation } from "@/components/client/graphql";
import { Icon } from "@/components/client/icon";
import { ChevronDown, ChevronRight } from "@/components/client/images";
import { DateUtility } from "@/components/common/utilities";

// =============================================================================
// Call Information Card Field
// =============================================================================

type CallInformationCardFieldProps = {
  /**
   * Label for the field.
   */
  label: string;
  /**
   * Value for the field.
   */
  value?: string | number;
};

// =============================================================================
// Call Information Card
// =============================================================================

/**
 * Call Information Card Props.
 */
export type CallInformationCardProps = {
  /**
   * Indicates that the call information card is visible.
   */
  visible?: boolean;
};

/**
 * A small card that displays information about a call.
 */
export function CallInformationCard({ visible }: CallInformationCardProps) {
  // ===========================================================================
  // ===========================================================================
  // Contexts
  // ===========================================================================
  // ===========================================================================

  const { hasActiveCommunicationLog, selectedCommunicationLog } =
    useCommunicationLogContext();

  // ===========================================================================
  // ===========================================================================
  // Operations
  // ===========================================================================
  // ===========================================================================

  const [createCall, { loading: creatingCall }] = useCallCreateMutation();

  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  const [expand, setExpand] = useState<boolean>(false);

  /**
   * The icon to display based on the status of the communication.
   */
  const icon = useMemo(() => {
    if (!selectedCommunicationLog?.status) {
      return null;
    }

    return CommunicationLogUtility.getIcon({
      status: selectedCommunicationLog?.status,
    });
  }, [selectedCommunicationLog?.status]);

  /**
   * The colors to display based on the status of the communication.
   * This includes the following:
   * - Background color of the card
   * - Icon color
   * - Text color
   */
  const color = useMemo(() => {
    if (!selectedCommunicationLog?.status) {
      return null;
    }

    return CommunicationLogUtility.getCardColor({
      status: selectedCommunicationLog.status,
    });
  }, [selectedCommunicationLog?.status]);

  /**
   * The phone number used for call back.
   *
   * A null phone number would hide the callback button.
   */
  const phoneNumber = useMemo(() => {
    if (!selectedCommunicationLog) {
      return null;
    }

    /**
     * Only show the callback button when the call is missed, declined,
     * no response, failed, or canceled.
     */
    switch (selectedCommunicationLog.status) {
      case CommunicationLogStatus.MISSED:
      case CommunicationLogStatus.DECLINED:
      case CommunicationLogStatus.CANCELED:
      case CommunicationLogStatus.NO_RESPONSE:
      case CommunicationLogStatus.FAILED:
        break;
      default:
        return null;
    }

    /**
     * Pick the correct phone number to use depending on the communication log
     * direction.
     * - Inbound calls would use the `from` phone number.
     * - Outbound calls would use the `to` phone number.
     */
    switch (selectedCommunicationLog.direction) {
      case CommunicationDirection.INBOUND:
        return selectedCommunicationLog.from;
      case CommunicationDirection.OUTBOUND:
        return selectedCommunicationLog.to;
      default:
        throw new Error(
          `Unknown direction: ${selectedCommunicationLog.direction}`,
        );
    }
  }, [selectedCommunicationLog]);

  // ===========================================================================
  // ===========================================================================
  // Variables
  // ===========================================================================
  // ===========================================================================

  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: (
        <div
          className="flex h-[74px] w-full items-center justify-between border-b border-neutral-mid-grey p-4"
          onClick={() => setExpand(!expand)}
        >
          <div className="inline-flex shrink grow basis-0 flex-col items-start justify-center gap-2">
            <div className="inline-flex items-center justify-start gap-1 self-stretch">
              {!!icon?.length && !!color && (
                <Icon src={icon} className={`!text-sm ${color.icon}`} />
              )}
              <div className="text-sm font-semibold text-tpl-navy">
                {selectedCommunicationLog?.status +
                  " " +
                  selectedCommunicationLog?.direction}
              </div>
            </div>
            {selectedCommunicationLog && (
              <div className="inline-flex w-full items-center justify-start gap-1">
                <CommunicationLogUser
                  communicationLog={{
                    id: selectedCommunicationLog.id,
                    missedCount: selectedCommunicationLog.missedCount,
                    user: selectedCommunicationLog.user,
                  }}
                  assignedUser={selectedCommunicationLog.user}
                  /** Only enabled when the call is inbound and the status is `Missed`, `Declined`, or `Canceled`. */
                  enabled={
                    selectedCommunicationLog.direction ===
                      CommunicationDirection.INBOUND &&
                    (selectedCommunicationLog.status ===
                      CommunicationLogStatus.MISSED ||
                      selectedCommunicationLog.status ===
                        CommunicationLogStatus.DECLINED ||
                      selectedCommunicationLog.status ===
                        CommunicationLogStatus.CANCELED)
                  }
                />
              </div>
            )}
          </div>
          <div className="flex items-center gap-2">
            {!!phoneNumber && (
              <Tooltip
                title={
                  hasActiveCommunicationLog
                    ? "Disabled During Active Logs"
                    : "Callback"
                }
              >
                <Button
                  disabled={creatingCall || hasActiveCommunicationLog}
                  icon={<Icon src="phone" className={`!text-sm !text-white`} />}
                  loading={creatingCall}
                  onClick={async (event) => {
                    event.stopPropagation();

                    await createCall({
                      variables: {
                        input: {
                          to: phoneNumber,
                        },
                      },
                    });
                  }}
                  type="primary"
                >
                  Callback
                </Button>
              </Tooltip>
            )}
            {expand ? (
              <ChevronDown className="h-6 w-6 text-tpl-navy-light" />
            ) : (
              <ChevronRight className="h-6 w-6 text-tpl-navy-light" />
            )}
          </div>
        </div>
      ),
      children: (
        <div className="flex flex-col items-start justify-start gap-2 self-stretch bg-white p-4 py-0">
          <FieldDisplayOnly
            label="From"
            value={selectedCommunicationLog?.from}
          />
          <FieldDisplayOnly label="To" value={selectedCommunicationLog?.to} />
          <FieldDisplayOnly
            label="Agent"
            value={
              selectedCommunicationLog?.user
                ? selectedCommunicationLog.user.name
                : "-"
            }
          />
          <FieldDisplayOnly
            label="Date"
            value={
              selectedCommunicationLog?.date
                ? DateUtility.getDate({
                    date: selectedCommunicationLog?.date,
                  })
                : "-"
            }
          />
          <FieldDisplayOnly
            label="Start"
            value={
              selectedCommunicationLog?.dateStarted
                ? DateUtility.getTime({
                    date: selectedCommunicationLog?.dateStarted,
                  })
                : "-"
            }
          />
          <FieldDisplayOnly
            label="End"
            value={
              selectedCommunicationLog?.dateEnded
                ? DateUtility.getTime({
                    date: selectedCommunicationLog?.dateEnded,
                  })
                : "-"
            }
          />
          <FieldDisplayOnly
            label="Direction"
            value={selectedCommunicationLog?.direction}
          />
          <FieldDisplayOnly
            label="Duration"
            value={selectedCommunicationLog?.duration + "s"}
          />
          <FieldDisplayOnly
            enableCopy
            label="Call ID"
            trim={-6}
            value={selectedCommunicationLog?.id}
          />
          <div className="flex w-full justify-center"></div>
        </div>
      ),
      showArrow: false,
      style: {
        padding: 0,
      },
    },
  ];

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  if (!visible) {
    return null;
  }

  return (
    <div
      className={`flex w-full animate-slide-left flex-col items-start justify-start self-stretch border-b-2 border-neutral-mid-grey bg-white ${
        !!selectedCommunicationLog ? "" : "hidden"
      }`}
    >
      <Collapse className="w-full" ghost items={items} />
    </div>
  );
}
