export function CallNotesCard() {
  return (
    <div className="flex h-[182px] flex-col items-start justify-start self-stretch rounded-md border border-slate-200 bg-white">
      <div className="flex h-[50px] flex-col items-start justify-start gap-4 self-stretch border-b border-slate-200 p-4">
        <div className="text-sm font-semibold leading-[17.50px] text-tpl-navy">
          Notes
        </div>
      </div>
      <div className="flex h-[132px] flex-col items-start justify-start gap-4 self-stretch p-4">
        <div className="flex h-[100px] flex-col items-center justify-center gap-2 self-stretch">
          <div className="relative h-10 w-16">
            <div className="absolute left-[9px] top-[1px] h-[34.74px] w-[46px]"></div>
          </div>
          <div className="text-center text-sm leading-snug text-gray-400">
            No Notes
          </div>
        </div>
      </div>
    </div>
  );
}
