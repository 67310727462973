"use client";

/**
 * Third-party libraries.
 */
import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useState,
} from "react";

/**
 * Application context.
 */

export type NetworkContext = {
  /**
   * Indicates that the application is connected to the network.
   */
  connected: boolean;
  /**
   * Sets the flag which indicates that the network is still loading.
   */
  loading: boolean;
  /**
   * Sets the flag which indicates that the application is connected to the network.
   */
  setConnected: Dispatch<SetStateAction<boolean>>;
  /**
   * Sets the flag which indicates that the network is still loading.
   */
  setLoading: Dispatch<SetStateAction<boolean>>;
};

/**
 * Application context.
 */
const NetworkContext = React.createContext<NetworkContext>({
  connected: false,
  loading: true,
  setConnected: () => {},
  setLoading: () => {},
});

/**
 * Use Application Context hook.
 */
export const useNetworkContext = () => {
  return React.useContext(NetworkContext);
};

/**
 * Application context provider.
 */
export const NetworkContextProvider = ({ children }: PropsWithChildren) => {
  // ===========================================================================
  // ===========================================================================
  // Hooks
  // ===========================================================================
  // ===========================================================================

  /**
   * Ant Design notification.
   */
  const [connected, setConnected] = useState<boolean>(false);

  /**
   * Indicates that the network is still loading.
   */
  const [loading, setLoading] = useState<boolean>(true);

  // ===========================================================================
  // ===========================================================================
  // Render
  // ===========================================================================
  // ===========================================================================

  return (
    <NetworkContext.Provider
      value={{
        connected,
        loading,
        setConnected,
        setLoading,
      }}
    >
      {children}
    </NetworkContext.Provider>
  );
};
